<template>
  <div style="width:100%">
    <div style="margin: 0 20px">
      <comp-part title="审核程控仪PDF的录入信息"></comp-part>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="60px">
        </el-table-column>
        <el-table-column
          prop="patientName"
          label="患者姓名"
          min-width="50px">
        </el-table-column>
        <el-table-column
          prop="recordNo"
          label="病历号"
          min-width="50px">
        </el-table-column>
        <el-table-column
          prop="recordName"
          label="随访周期"
          min-width="50px">
        </el-table-column>
        <el-table-column
          prop="createAt"
          label="创建时间"
          min-width="50px">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ goTime(scope.row.createAt) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          width="114px"
          label="操作">
          <template slot-scope="scope">
            <el-button v-if="isOpenHandle" type="text" @click="handleCheck(scope.row)">校对数据</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 60px;"></div>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="totalPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   getBaselineInfo,
//   getDictionary
// } from '@/service/module/followup'
import CompPart from '@/components/Part'
import { getProofreadDataList } from '@/service/module/task'
export default ({
  components: {
    CompPart
  },
  data () {
    return {
      tableData: [],
      totalPage: 0,
      currentPage: 1,
      pageSize: 20,
      isOpenHandle: false
    }
  },
  mounted () {
    this.getDataList(1, this.pageSize)
    this._send('$pageView', '待处理任务进入情况', '待处理任务页面 加载成功时触发', {
      accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
      doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
      hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
    })
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'verify-task:verify-data') {
        this.isOpenHandle = true
      }
    })
  },
  methods: {
    async getDataList (pageNum, pageSize) {
      try {
        const res = await getProofreadDataList({
          pageNum: pageNum,
          pageSize: pageSize
        })
        if (res) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
          this.currentPage = res.data.currentPage
          this.pageSize = res.data.pageSize
        }
      } catch (e) {
        console.log('awaitList-err:', e)
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.getDataList(val, this.pageSize)
    },
    async handleCheck (data) {
      this.$router.push({
        path: 'proofread',
        query: {
          reportId: data.reportId
        }
      })
    },
    goTime (val) {
      var timestamp4 = new Date(val)
      return timestamp4.toLocaleDateString().replace(/\//g, '-') + ' ' + timestamp4.toTimeString().substr(0, 8)
    }
  }
})
</script>

<style scoped lang="scss">
  /* .el-pagination {
    float: right;
  } */
  .paginationClass {
    background: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 210px;
    z-index: 100;
    padding: 14px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  ::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }
</style>
